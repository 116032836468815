import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { FaAngleDown } from 'react-icons/fa';
import { FaSlidersH } from 'react-icons/fa';


export default function TemporaryDrawer(props) {
  const [state, setState] = React.useState({
    left: false,
  });

  const handleChange = (propertie, value) => {
    props.toggleFilter(propertie, value)
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === `keydown` && (event.key === `Tab` || event.key === `Shift`)) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      <button
        className="z-50 rounded-full p-2 m-4 fixed left-0 bottom-0 shadow bg-black"
        onClick={toggleDrawer(`left`, true)}>
          <FaSlidersH className="inline m-1 h-6 w-6 text-white"/>
          <span className="hidden sm:inline mx-1 text-white">filtros</span>
          </button>
      <Drawer anchor="left" onClose={toggleDrawer(`left`, false)} open={state[`left`]}>
      {props.filters.map((filter, index) => {
        return (
          <div className="w-64" key={index}>
            <div className="p-2 text-white bg-primary">
              {filter.key}
              <FaAngleDown className="inline align-middle mr-1 h-3 w-3" />
            </div>
            <Divider />
            <div className="p-2">
              {filter.options.map((option) => {
                const isChecked = filter.selecteds.some((selected) => {
                  return option === selected;
                })
                return (<>
                  <div className={`inline-block rounded-full text-xs px-2 py-1 m-1 font-semibold ` + (isChecked ? `bg-primary text-white` : `bg-gray-200 text-gray-600`)}>
                    <a href="#" onClick={()=>handleChange(filter.key, option)}>
                      {option}
                    </a>
                  </div>
                </>)
              })}
            </div>
          </div>
        )
      })}
    </Drawer>
    </div>
  );
}
