import React, { useEffect, useState } from 'react';
import  StoreContext  from '../context/StoreContext';
import { graphql } from 'gatsby';
import ProductList from '../components/ProductList';
import FilterMenu from '../components/FilterMenuDrawer';

const CategoryTemplate = ({ pageContext, data }) => {
  const [productsFiltered, setProductsFiltered] = useState(data.allWcProducts.edges);

  const [filterOptions, setFilterOptions] = useState([
    {
      key: `Cor`,
      options: [],
      selecteds: []
    },
    {
      key: `Tamanho`,
      options: [],
      selecteds: []
    },
    {
      key: `Acabamento`,
      options: [],
      selecteds: []
    },
    {
      key: `Formato`,
      options: [],
      selecteds: []
    }
  ]);

  const getPropertiePosition = (propertie) => {
    const keys = [
      {
        key: `Cor`,
        index: 0,
      },
      {
        key: `Tamanho`,
        index: 1,
      },
      {
        key: `Acabamento`,
        index: 2,
      },
      {
        key: `Formato`,
        index: 3,
      },
    ]
    const keySelected = keys.filter(({key}) => {
      return key === propertie;
    })

    return keySelected && keySelected.length ? keySelected[0].index : null;
  }

  const addToFilter = (propertie, value) => {
    const newFilterOptions = [...filterOptions];
    newFilterOptions[getPropertiePosition(propertie)].selecteds.push(value);
    setFilterOptions(newFilterOptions);
  }

  const toggleFilter = (propertie, value) => {
    const newFilterOptions = [...filterOptions];
    const myCurrentFilter = newFilterOptions[getPropertiePosition(propertie)].selecteds.includes(value);

    if(!myCurrentFilter) {
      newFilterOptions[getPropertiePosition(propertie)].selecteds.push(value);
    } else {
      newFilterOptions[getPropertiePosition(propertie)].selecteds = newFilterOptions[getPropertiePosition(propertie)]
        .selecteds.filter((selected) => {
          return selected !== value;
        })
    }

    setFilterOptions(newFilterOptions);
  }

  useEffect(() => {
      let newFilterOptions = [...filterOptions];

      data.allWcProducts.edges.map(({node: product}) => {
        if (product.attributes) {
          product.attributes.map((attribute, index) => {
            let indexPropertie = getPropertiePosition(attribute.name);
            if (indexPropertie !== undefined && indexPropertie !== null) {
              attribute.options.forEach((option) => {
                newFilterOptions[indexPropertie].options.push(option)
              });
            }
          })
        }
      })

      // make unique
      newFilterOptions.forEach((filter) => {
        filter.options = Array.from(new Set(filter.options))
      })

      setFilterOptions(newFilterOptions);
  }, [data.allWcProducts.edges])

  useEffect(() => {
    let newProductList = [...data.allWcProducts.edges];

    newProductList = newProductList.filter(({node: product}) => {
      const filtersNegative = [];

      filterOptions.forEach((filter) => {
        filter.selecteds.forEach((selected) => {
          product.attributes.forEach((attr) => {
            if (attr.name === filter.key) {
              const existEquals = filter.selecteds.some((filter) => {
                return filter.includes(attr.options);
              })

              if (!existEquals) {
                filtersNegative.push(attr.name)
              }
            }
          })
        })
      })
      return filtersNegative.length === 0;
    })
    setProductsFiltered(newProductList)

  }, [filterOptions, data.allWcProducts.edges])

  return (
    <StoreContext.Consumer>
      {context => {
        if (!context.state) {
          return <></>
      }
      return (<>
          {/* <div>
            { category.name }
          </div> */}
          <div className="flex flex-col">
            <FilterMenu
              addToFilter={addToFilter}
              filters={filterOptions}
              toggleFilter={toggleFilter}
            />
            <ProductList
              addProductToCart={(id, values) => context.addProductToCart( id, values)}
              products={productsFiltered}
            />
          </div>
        </>)
      }}
    </StoreContext.Consumer>

  );
};

export default CategoryTemplate;


// allWcProducts(sort: { fields: [categories] }) {
export const pageQuery = graphql`
query($categories___slug: String) {
  allWcProducts(
    sort: {fields: [name]},
    filter: {categories: {elemMatch: {slug: {eq: $categories___slug}}}, stock_status: {eq: "instock"}},
    ) {
    edges {
      node {
        id
        wordpress_id
        description
        images {
          src
        }
        slug
        sku
        short_description
        sale_price
        price
        on_sale
        name
        regular_price
        categories {
          wordpress_id
          slug
          name
        }
        attributes {
          id
          name
          options
          position
          visible
        }
      }
    }
  }
}
`
